<template>
  <DashboardVideo />
  <div class="container d-flex align-items-center justify-content-center z-2">
    <div class="row mobile align-content-between g-0">
      <div class="col-12 ps-3 pe-3 d-flex align-items-center justify-content-end theme-header">
        <router-link :to="{
          name: componentAdmin
        }" class="btn-back opacity-0">
        </router-link>
        <a href="#" class="m-auto"><img :src="`/img/ui-theme/` + theme.devLogo" style="height:50px" /></a>
        <button class="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight">
          <img src="/img/ui-theme/contact-icon-rev.svg" alt="Fullscreen">
        </button>
      </div>
      <div class="col-12 d-flex align-items-center justify-content-center z-2">
        <div class="row g-0">
          <div class="col-12">
            <a v-for="assetType in assetTypes" :key="assetType.Guid" :href="assetUrl(assetType)"
              :target="targetOption(assetType)" class="btn-theme-menu mb-2">
              {{ assetType.ObjectName }}
            </a>
            <router-link :to="{
              name: 'TopLevelPdfViewDetail',
              params: {
                assetType: 'brochure'
              }
            }" class="btn-theme-menu mb-2">
              Price List
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex align-items-end justify-content-end sticky-bottom theme-footer">
        <img :src="`/img/ui-theme/` + theme.agentLogo" class="position-relative theme-footer-logo" style="height:35px" />
      </div>
    </div>
  </div>
  <OffCanvas />
</template>

<script>
import DashboardVideo from "@/components/DashboardVideo.vue";
import OffCanvas from "@/components/OffCanvas.vue";
import global from '../global'
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";
import sortArray from 'sort-array'

export default {
  name: "DashboardHome",
  components: {
    DashboardVideo,
    OffCanvas
  },
  data() {
    return {
      assetTypes: null,
      componentName: "AssetType",
      componentAdmin: "Admin",
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/'),
      brochureUrl: "https://metaluxe-demo-pwa.b-cdn.net/pdf/Flyer.pdf"
    }
  },
  created() {
    BunnyNetService.getFolders(this.folderStructure)
      .then(response => {
        // Remap array
        console.log(response)
        const menuItems = response.data.map((item) => {
          return {
            Guid: item.Guid,
            ObjectName: unslugify(item.ObjectName),
            LinkName: item.ObjectName,
            ComponentName: this.componentName,
            Path: item.Path,
            IsDirectory: item.IsDirectory,
            StorageZoneName: item.StorageZoneName
          };
        });

        const customMenu = sortArray(menuItems, {
          by: ['LinkName'],
          order: 'LinkName',
          customOrders: {
            LinkName: this.theme.assetsMenuOrder
          }
        })

        this.assetTypes = customMenu
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    assetUrl(assetType) {
      if (assetType.LinkName === 'Metaluxe Showreel') {
        return 'https://youtu.be/DhNKKpNmg9U?si=ERNEu5Kbj5RXWFew';
      } else if (assetType.LinkName === 'Florian (Full Package) 75k') {
        return 'https://florian.metaluxe.com.au';
      } else {
        return `asset/${assetType.LinkName}`
      }
    },
    targetOption(assetType) {
      if (assetType.LinkName === 'Metaluxe Showreel') {
        return '_blank';
      } else if (assetType.LinkName === 'Florian (Full Package) 75k') {
        return '_blank';
      } else {
        return `_self`;
      }
    }
  },
  setup() {
    const { theme } = global

    return {
      theme
    }
  }
};
</script>
