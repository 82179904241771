import { reactive } from "vue";

const theme = reactive({
    agentLogo: "Footer.png",
    devLogo: "Header.png",
    apiUrl: "https://api.metaluxe.com.au/",
    bunnyApiUrl: "https://api.bunny.net/",
    accessKey: "07aeb6d1-92b0-48fc-9fa3-e202bc32d8753a016979-9f0b-4b1d-8027-5575c13bad47",
    streamToken: "d54ed664-151f-46ec-b380-1702ccb3e4ab",
    videoLibraryId: "150889",
    streamCdnUrl: "https://vz-c9baf3c0-e0e.b-cdn.net",
    streamApiKey: "e173bff1-ac1f-4a7d-a52d0bd2455a-cfd0-4fdf",
    storageApiPassword: "1dd7f53e-ce23-4900-811825c4e88f-530e-4cd7",
    cdnUrl: "https://mode-pwa.b-cdn.net",
    devFolder: "/mode-pwa/",
    googleAnalytics: "",
    videosIcon: "/img/ui-theme/videos-icon.svg",
    rendersIcon: "/img/ui-theme/renders-icon.svg",
    rangeIcon: "/img/ui-theme/range-icon.svg",
    panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
    floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
    socialsIcon: "/img/ui-theme/socials-icon.svg",
    dashboardMp4: "theme/dashboard/4K_dashboard.mp4",

    // top level menu
    assetsMenuOrder: [
        'Metaluxe Showreel',
        'Tower Teaser 10k',
        'Florian (Full Package) 75k',
        'Big Concept 30k',
        'Hospital Teaser 10k',
        'Residential Teaser 10k',
        'Restaurant Teaser 10k',
        'Office Teaser 10k'
    ],

    bed1MenuOrder: [
        'videos',
        'virtual-tour',
        'balcony-views',
        'floorplans',
        'range',
        'renders',
        'pdf',
        'socials',
    ],

    metaluxeAsset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [],
        panoramasUrl: [],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
        socials: [],
        socialsName: [],
    },

    towerAsset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [],
        panoramasUrl: [],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
        socials: [],
        socialsName: [],
    },

    florianAsset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [],
        panoramasUrl: [],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
        socials: [],
        socialsName: [],
    },

    conceptAsset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Urban Quarter Virtual Tour'
        ],
        panoramasUrl: [
            'https://kuula.co/share/collection/7FnDP?logo=0&info=1&fs=1&vr=1&sd=1&thumbs=1'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
        socials: [],
        socialsName: [],
    },

    hospitalAsset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Hospital Teaser'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/AdifnQUfB'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
        socials: [],
        socialsName: [],
    },

    residentialAsset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Residential Teaser'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/HDNk3mS_c3d'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
        socials: [],
        socialsName: [],
    },

    restaurantAsset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Restaurant Teaser'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/BhFmCxQyh'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
        socials: [],
        socialsName: [],
    },

    officeAsset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Office Teaser'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/nozvhP3vA'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
        socials: [],
        socialsName: [],
    },

    
    
    

    

    

    
});

export default { theme };